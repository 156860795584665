import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['dialog'];
    static values = {}

    open(event) {
        if (event.target.tagName.toLowerCase() === 'li') {
            this.dialogTarget.show();
        }
    }
}