import { Loader } from "@googlemaps/js-api-loader"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [""];

    connect() {
        this.markers = [];
        this.lines = [];
        this.pois = [];

        const loader = new Loader({
            apiKey: "AIzaSyCqMvytQfwyUAJMIPNpbas9fZM85px5hBY",
            version: "weekly"
        });

        loader.load().then(() => {
            const worldCenter = new window.google.maps.LatLng(32.249974, 5.800781);

            this.marker_path = "M 0 0 q 2.0342 0 3.4615 1.4273 t 1.4273 3.4615 q 0 1.0171 -0.5089 2.3296 t -1.2306 2.4612 t -1.4273 2.149 t -1.1977 1.5911 l -0.525 0.5579 q -0.1967 -0.2296 -0.525 -0.6069 t -1.1816 -1.5092 t -1.4931 -2.1987 t -1.1648 -2.4115 t -0.525 -2.3625 q 0 -2.0342 1.4273 -3.4615 t 3.4615 -1.4273";

            this.marker_normal = {
                path: this.marker_path,
                fillColor: "#0F3A68",
                fillOpacity: 1,
                strokeWeight: 1.5,
                strokeColor: "white",
                rotation: 0,
                scale: 2,
                anchor: new google.maps.Point(0, 14),
            };

            this.marker_start = {
                path: this.marker_path,
                fillColor: "#1FD649",
                fillOpacity: 1,
                strokeWeight: 1.5,
                strokeColor: "white",
                rotation: 0,
                scale: 2,
                anchor: new google.maps.Point(0, 14),
            };

            this.marker_end = {
                path: this.marker_path,
                fillColor: "#FE5F55",
                fillOpacity: 1,
                strokeWeight: 1.5,
                strokeColor: "white",
                rotation: 0,
                scale: 2,
                anchor: new google.maps.Point(0, 14),
            };

            this.marker_poi = {
                path: this.marker_path,
                fillColor: "#F7FF4D",
                fillOpacity: 1,
                strokeWeight: 1.5,
                strokeColor: "white",
                rotation: 0,
                scale: 2,
                anchor: new google.maps.Point(0, 14),
            };

            this.lineSymbol = {
                path: 'M 0,-1 0,1',
                strokeOpacity: 1,
                strokeWeight: 3,
                scale: 4
            };

            this.map = new google.maps.Map(this.element, {
                zoom: 4,
                center: worldCenter,
            });
        });
    }

    setMapOnItems(list, map) {
        for (let i = 0; i < list.length; i++) {
            list[i].setMap(map);
        }
    }

    hideItemsOnMap(list) {
        this.setMapOnItems(list, null);
    }

    showItemsOnMap(list) {
        this.setMapOnItems(list, map);
    }

    deleteItems(list) {
        this.hideItemsOnMap(list);
        list.length = 0;
    }

    updateMap(data) {
        this.deleteItems(this.markers);
        this.deleteItems(this.lines);
        document.querySelector("aside .dialogs").innerHTML = "";

        if (data.routes) {
            data.routes.forEach(route => {
                let points = route.points;
                let route_coords = [];
                points.forEach(point => {
                    let marker_image;
                    let point_coords = { lat: point.lat, lng: point.lng };
                    route_coords.push(point_coords);

                    if (point.is_start) {
                        marker_image = this.marker_start
                    } else if (point.is_end) {
                        marker_image = this.marker_end
                    } else {
                        marker_image = this.marker_normal
                    }
                    
                    if (point.type != "HiddenPoint") {
                        // In here
                        let marker = new google.maps.Marker({
                            title: point.name,
                            position: new window.google.maps.LatLng(point.lat, point.lng),
                            icon: marker_image
                        });
                        this.markers.push(marker);

                        this.dialog(marker, point, 'point');
                    }
                });

                let route_line
                if (route.complete == true) {
                    route_line = new google.maps.Polyline({
                        path: route_coords,
                        geodesic: false,
                        strokeColor: "#" + route.color,
                        strokeOpacity: 1.0,
                        strokeWeight: 3,
                    });
                } else {
                    route_line = new google.maps.Polyline({
                        path: route_coords,
                        geodesic: false,
                        strokeColor: "#" + route.color,
                        strokeOpacity: 0,
                        icons: [{
                            icon: this.lineSymbol,
                            offset: '0',
                            repeat: '20px'
                        }],
                    });
                }

                this.lines.push(route_line);

                this.dialog(route_line, route, 'route');
            });
        }

        if (data.points_of_interest) {
            this.deleteItems(this.pois);

            data.points_of_interest.forEach(point => {
                let marker = new google.maps.Marker({
                    title: point.name,
                    position: new window.google.maps.LatLng(point.lat, point.lng),
                    icon: this.marker_poi
                });
                this.pois.push(marker);

                this.dialog(marker, point, 'interest_spot');
            });
        } else {
            this.deleteItems(this.pois);
        }

        if (data.autozoom) {
            if (data.routes || data.points_of_interest) {
                this.bounds = new google.maps.LatLngBounds();

                if (data.routes) {
                    for (let i = 0; i < this.markers.length; i++) {
                        this.bounds.extend(this.markers[i].getPosition());
                    }
                }

                if (data.points_of_interest) {
                    for (let i = 0; i < this.pois.length; i++) {
                        this.bounds.extend(this.pois[i].getPosition());
                    }
                }

                this.map.fitBounds(this.bounds);
            }
        }

        this.setMapOnItems(this.markers, this.map);
        this.setMapOnItems(this.lines, this.map);
        this.setMapOnItems(this.pois, this.map);
    }

    dialog(click_source, item, type) {
        document.querySelector('.dialogs').innerHTML += item.dialog;

        click_source.addListener('click', function() {
            document.querySelector(".dialogs #" + type + "_" + item.id + "_dialog").show();
        });
    }
}