import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form"];

    static outlets = ["map"];

    connect() {
        this.token = document.querySelector('meta[name="csrf-token"]').content;

        // if (localStorage.getItem('filters') !== null) {
        //     let filters = localStorage.getItem('filters');
        // } else {
        //     let filters = this.get_filters_state_from_form();
        //     localStorage.setItem('filters', filters);
        // }
        
        // this.submit();
    }

    get_filters_state() {
        let data = new FormData(this.formTarget);
        // for (let [key, value] of data) {
        //     console.log(key + ': ' + value);
        // }

        return JSON.stringify(Object.fromEntries(data));
        // console.log(jsonString);
    }

    submit() {
        let jsonString = this.get_filters_state();

        fetch('api/filtered_data.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': this.token,
            },
            credentials: 'same-origin',
            body: jsonString
        }).then(response => response.json())
        .then((data) => {
            this.mapOutlet.updateMap(data);
        });
    }
}
 