import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    view: String,
  }

  static targets = ["typeSelectInput", "marriagedateDateInput", "divorcedBooleanInput", "marriagedatepartsSelectInput"];

  async connect() {
    console.log('view ->', this.viewValue)
  }

  typeChanged() {
    console.log('type was changed');

    let value = this.typeSelectInputTarget.value;

    if (value == "Spouse") {
        console.log('Selected SPOUSE');
        this.divorcedBooleanInputTarget.disabled = false;
        this.marriagedatepartsSelectInputTarget.disabled = false;

        this.marriagedateDateInputTarget.disabled = false;
        this.marriagedateDateInputTarget.nextElementSibling.disabled = false;
        this.marriagedateDateInputTarget.nextElementSibling.nextElementSibling.disabled = false;
    } else {
        console.log("Selected PERSON");
        this.divorcedBooleanInputTarget.disabled = true;
        this.marriagedatepartsSelectInputTarget.disabled = true;

        this.marriagedateDateInputTarget.disabled = true;
        this.marriagedateDateInputTarget.nextElementSibling.disabled = true;
        this.marriagedateDateInputTarget.nextElementSibling.nextElementSibling.disabled = true;
    }
  }
}