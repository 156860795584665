import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

function findEnvelopeSizeOfAbsolutelyPositionedChildren(containerSelector) {
    var leftPositions = [];
    var topPositions = [];
    var widths = [];
    var heights = [];

    $(containerSelector).each(function (i) {
        let vals = $(this)[0].getBoundingClientRect();

        leftPositions.push(vals.x)
        topPositions.push(vals.y)
        widths.push(vals.width)
        heights.push(vals.height)
    });

    let width = (Math.max(...leftPositions) - Math.min(...leftPositions)) + Math.max(...widths) + 'px';
    let height = (Math.max(...topPositions) - Math.min(...topPositions)) + Math.max(...heights) + 'px';

    return {
        width: width,
        height: height
    };
}

// Declare a global datastore
nodeStore = new Array();

(function ($) {
    $.fn.genealogyPlotter = function () {
        var options = $.extend(
            {
                before: function () {},
                after: function () {},
            },
            arguments[0] || {}
        );

        var config = {
            nodeCSS: {
                width: 160, //192, //240,
                height: 200,
                position: "absolute",
                left: 0,
                top: 0,
            },
            nodeGutterSize: 20,
            spacingAround: 0, //150,
            minusX: 0
        };
        if (options) $.extend(config, options);

        options.before.call(this);

        // Store the container
        var $genealogyContainer = $(this);

        // console.log($genealogyContainer);

        // let viewportAbove600 = true;
        // window.addEventListener('resize', function(event){
        //     console.log('test');
        //     _thirdWalkSvg();
        // });

        // Generate the grid
        $genealogyContainer.find(".node").each(function () {
            // Store the current node
            var $node = $(this);

            // console.log($node);

            // Retrieve the grid position
            var nodePosition = $node
                .find(".genealogy-person-reference")
                .text()
                .split(".");

            // console.log(nodePosition);

            // Add the generation and position to the data store, also add them to the element to make selection easier later
            $node
                .data("generation", parseInt(nodePosition[0]))
                .data("position", parseInt(nodePosition[1]))
                .data(
                    "genpos",
                    parseInt(nodePosition[0]) + "." + parseInt(nodePosition[1])
                );
            $node
                .attr("generation", $node.data("generation"))
                .attr("position", $node.data("position"));
            nodeStore[$node.data("genpos")] = {
                generation: parseInt(nodePosition[0]),
                position: parseInt(nodePosition[1]),
                genpos:
                    parseInt(nodePosition[0]) + "." + parseInt(nodePosition[1]),
            };

            // Get the standard CSS
            $node.css({
                position: config.nodeCSS.position,
                left: config.nodeCSS.left,
                top: config.nodeCSS.top,
            });
        });

        // Perform the first walk - All the Logic
        _firstWalk($genealogyContainer.find(".node:first"));

        // Perform the second walk - Position the nodes
        _secondWalk($genealogyContainer.find(".node:first"), 0, 0);

        if (config.minusX != 0) {
            _adjustXPositionsByMinusX();
        }

        // Perform the third walk - Draw the tree connecting lines
        _thirdWalkSvg();

        // Set size of UL
        setTimeout(function () {
            var ulDimensions = findEnvelopeSizeOfAbsolutelyPositionedChildren(
                "ul.top-level .node"
            );

            $genealogyContainer.css({
                width: "calc(" + ulDimensions.width + " + " + "2rem)",
                height: "calc(" + ulDimensions.height + " + " + "2rem)",
            });

            $genealogyContainer.find("ul.top-level").css({
                width: ulDimensions.width,
                height: ulDimensions.height,
            });
        }, 100);

        options.after.call(this);

        // Don't break the chain!
        return this;

        // Declare the helper functions
        function _firstWalk($node) {
            // Get the nodeStore location key
            var nsKey = $node.data("genpos");
            var node = nodeStore[nsKey];

            // Setup the data stores
            node.XPosition = 0;
            node.YPosition = 0;
            node.prelim = 0;
            node.modifier = 0;
            node.lineToChildren = false;

            // prefetch some overused callings to reduce number of executions
            var $immediateParent = $node.parent("li");
            var $nextUL = $node.next("ul");

            var $pN = $node.closest("ul").prev(".node");
            node.parentNode = $pN.length ? $pN : null;

            var $lS = $immediateParent.prev("li").find(".node:first");
            node.leftSibling = $lS.length ? $lS : null;

            var $rS = $immediateParent.next("li").find(".node:first");
            node.rightSibling = $rS.length ? $rS : null;

            var $lN = $genealogyContainer.find(
                ".node[generation=" +
                    $node.data("generation") +
                    "][position=" +
                    ($node.data("position") - 1) +
                    "]"
            );
            node.leftNeighbor = $lN.length ? $lN : null;

            var $rN = $genealogyContainer.find(
                ".node[generation=" +
                    $node.data("generation") +
                    "][position=" +
                    ($node.data("position") + 1) +
                    "]"
            );
            node.rightNeighbor = $rN.length ? $rN : null;

            var $fC = $nextUL.children("li:first").find(".node:first");
            node.firstChild = $fC.length ? $fC : null;

            var $lC = $nextUL.children("li:last").find(".node:first");
            node.lastChild = $lC.length ? $lC : null;

            node.isFirstChild = $immediateParent.is(":first-child")
                ? true
                : false;

            node.isLastChild = $immediateParent.is(":last-child")
                ? true
                : false;

            nodeStore[nsKey] = node;

            if ($nextUL.children("li").length == 0) {
                if ((lS = node.leftSibling)) {
                    var lSNode = nodeStore[lS.data("genpos")];
                    nodeStore[nsKey].prelim =
                        lSNode.prelim +
                        config.nodeCSS.width +
                        config.nodeGutterSize;
                } else nodeStore[nsKey].prelim = 0;
            } else {
                $nextUL.children("li").each(function () {
                    var $n = $(this).find(".node:first");
                    _firstWalk($n);
                });

                var fChild =
                    nodeStore[nodeStore[nsKey].firstChild.data("genpos")];
                var lChild =
                    nodeStore[nodeStore[nsKey].lastChild.data("genpos")];
                var midPoint =
                    fChild.prelim +
                    (lChild.prelim - fChild.prelim + config.nodeCSS.width) / 2;
                midPoint -= config.nodeCSS.width / 2;

                var lSPointer = nodeStore[nsKey].leftSibling;
                var lS = lSPointer ? nodeStore[lSPointer.data("genpos")] : null;
                if (lS != null) {
                    nodeStore[nsKey].prelim =
                        lS.prelim +
                        config.nodeCSS.width +
                        config.nodeGutterSize;
                    nodeStore[nsKey].modifier =
                        nodeStore[nsKey].prelim - midPoint;
                    _apportion($node);
                } else {
                    nodeStore[nsKey].prelim = midPoint;
                }
            }
        }

        function _apportion($node) {
            // Get the nodeStore location key
            var nsKey = $node.data("genpos");

            var fCPointer = nodeStore[nsKey].firstChild;
            var fChild = fCPointer ? nodeStore[fCPointer.data("genpos")] : null;
            var fCLNPointer = nodeStore[fChild.genpos].leftNeighbor;
            var fChildLeftNeighbor = fCLNPointer
                ? nodeStore[fCLNPointer.data("genpos")]
                : null;

            while (fChild != null && fChildLeftNeighbor != null) {
                var modifierSumRight = 0;
                var modifierSumLeft = 0;
                var rightAncestor = fChild;
                var leftAncestor = fChildLeftNeighbor;

                while (
                    rightAncestor.parentNode != null &&
                    leftAncestor.parentNode != null &&
                    rightAncestor.generation > nodeStore[nsKey].generation
                ) {
                    rightAncestor =
                        nodeStore[rightAncestor.parentNode.data("genpos")];
                    leftAncestor =
                        nodeStore[leftAncestor.parentNode.data("genpos")];
                    modifierSumRight += rightAncestor.modifier;
                    modifierSumLeft += leftAncestor.modifier;
                }

                var totalGap =
                    fChildLeftNeighbor.prelim +
                    modifierSumLeft +
                    config.nodeCSS.width +
                    config.nodeGutterSize -
                    (fChild.prelim + modifierSumRight);
                if (totalGap > 0) {
                    var subtreeAux = nodeStore[nsKey];
                    var numSubtrees = 0;
                    while (subtreeAux.genpos != leftAncestor.genpos) {
                        numSubtrees++;
                        var stAPointer = subtreeAux.leftSibling;
                        if (stAPointer != null) {
                            subtreeAux = nodeStore[stAPointer.data("genpos")];
                        } else {
                            subtreeAux = null;
                            break;
                        }
                    }

                    if (subtreeAux != null) {
                        var subtreeMoveAux = nodeStore[nsKey];
                        var singleGap = totalGap / numSubtrees;
                        while (subtreeMoveAux.genpos != leftAncestor.genpos) {
                            subtreeMoveAux.prelim += totalGap;
                            subtreeMoveAux.modifier += totalGap;
                            totalGap -= singleGap;
                            var stMAPointer = subtreeMoveAux.leftSibling;
                            if (stMAPointer != null) {
                                subtreeMoveAux =
                                    nodeStore[stMAPointer.data("genpos")];
                            } else {
                                subtreeMoveAux = null;
                                break;
                            }
                        }
                    }
                }

                if (!fChild.firstChild) {
                    while ((fChild = fChild.rightNeighbor)) {
                        fChild = nodeStore[fChild.data("genpos")];
                        if (fChild.firstChild) {
                            fChild =
                                nodeStore[fChild.firstChild.data("genpos")];
                            break;
                        }
                    }
                } else {
                    if (fChild.firstChild)
                        fChild = nodeStore[fChild.firstChild.data("genpos")];
                    else fChild = null;
                }
                if (fChild) {
                    if (fChild.leftNeighbor)
                        fChildLeftNeighbor =
                            nodeStore[fChild.leftNeighbor.data("genpos")];
                    else fChildLeftNeighbor = null;
                }
            }
        }

        function _secondWalk($node, X, Y) {
            var nsKey = $node.data("genpos");
            var node = nodeStore[nsKey];

            // If X is negative, add this value to X to all nodes
            if (X < 0) {
                config.minusX = -X;
            }

            node.XPosition = node.prelim + X - config.nodeGutterSize + config.spacingAround; // was + 240
            node.YPosition = Y - config.nodeGutterSize + config.spacingAround; // was + 160
            nodeStore[nsKey] = node;

            $node.css({
                left: node.XPosition + config.nodeGutterSize,
                top: node.YPosition + config.nodeGutterSize,
            });

            var fChild = node.firstChild;
            if (fChild)
                _secondWalk(
                    fChild,
                    X + node.modifier,
                    Y + config.nodeCSS.height + config.nodeGutterSize
                );

            var rSibling = node.rightSibling;
            if (rSibling) _secondWalk(rSibling, X, Y);
        }



        // If ANY node has a minus X position (caused by a first child not having any children and subsequent child 
        // having children that stretch underneath first child to the left), then iterate over all nodes and adjust 
        // left position by that negative value
        function _adjustXPositionsByMinusX() {
            $('.node').each(function() {
                $node = $(this);

                var newX = parseInt($node.css('left')) + config.minusX + 'px';
                $node.css('left', newX);
            })
        }



        function _thirdWalk() {
            var genealogyCanvas = document.getElementById("genealogy-canvas");
            var $genealogyCanvas = $(genealogyCanvas);
            if ($genealogyCanvas.attr("width") == 1) {
                var wWidth = $(document).width();
                var wHeight = $(document).height();
                $genealogyCanvas.attr("width", wWidth).attr("height", wHeight);
                $genealogyCanvas.css({
                    display: "block",
                    position: "relative",
                    left: 0,
                    top: 0,
                    paddingRight: config.nodeGutterSize,
                    paddingBottom: config.nodeGutterSize,
                });
            }

            // Get the drawing canvas
            var drawingSurface = genealogyCanvas.getContext("2d");

            // Go through each of the nodes, draw a line from them to their parent
            $genealogyContainer.find(".node").each(function () {
                $node = $(this);
                node = nodeStore[$node.data("genpos")];
                if (($pN = node.parentNode)) {
                    // Get node + parent offsets
                    var nOffsets = $node.offset();
                    var pOffsets = $pN.offset();

                    // Work out various points
                    var points = {
                        node: {},
                        parent: {},
                        midpoint1: {},
                        midpoint2: {},
                    };

                    // Top of node
                    points.node.x =
                        parseInt(nOffsets.left + config.nodeCSS.width / 2) +
                        0.5;
                    points.node.y = nOffsets.top + 0.5;

                    // Bottom of parent
                    points.parent.x =
                        parseInt(pOffsets.left + config.nodeCSS.width / 2) +
                        0.5;
                    points.parent.y =
                        parseInt(pOffsets.top + $pN.outerHeight()) + 0.5;

                    // Middle of line area, above node
                    points.midpoint1.x = points.node.x;
                    points.midpoint1.y = points.node.y - 10;

                    // Middle of line area, below parent
                    points.midpoint2.x = points.parent.x;
                    points.midpoint2.y = points.midpoint1.y;

                    // Draw the line from child to parent
                    drawingSurface.strokeStyle = "#84a2b2"; // Use Rothschild blue
                    drawingSurface.beginPath();
                    drawingSurface.moveTo(points.node.x, points.node.y);
                    drawingSurface.lineTo(
                        points.midpoint1.x,
                        points.midpoint1.y
                    ); // Little vertical line above node
                    drawingSurface.lineTo(
                        points.midpoint2.x,
                        points.midpoint2.y
                    ); // Horizontal lines from slightly above node to directly below parent node
                    drawingSurface.lineTo(points.parent.x, points.parent.y); // Vertical line from directly underneath parent to bottom of parent
                    drawingSurface.stroke();
                }
            });
        }

        function _thirdWalkSvg() {
            // $('svg#genealogy-canvas').remove();

            var svg = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "svg"
            );
            var $svg = $(svg);

            $svg.attr("id", "genealogy-canvas");
            $svg.css({
                display: "block",
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
            });

            // Go through each of the nodes, draw a line from them to their parent
            $genealogyContainer.find(".node").each(function () {
                $node = $(this);
                node = nodeStore[$node.data("genpos")];

                if (($pN = node.parentNode)) {
                    pnNode = nodeStore[$pN.data("genpos")];
                    // Get node + parent offsets
                    var nOffsets = $node.position();
                    var pOffsets = $pN.position();

                    // Work out various points
                    var points = {
                        node: {},
                        parent: {},
                        parentTop: {},
                        midpoint1: {},
                        midpoint2: {},
                    };

                    // Top of node
                    points.node.x =
                        parseInt(nOffsets.left + config.nodeCSS.width / 2) +
                        0.5;
                    points.node.y = nOffsets.top + 0.5;

                    // Top of parent
                    points.parentTop.x =
                        parseInt(pOffsets.left + config.nodeCSS.width / 2) +
                        0.5;
                    points.parentTop.y =
                        parseInt(pOffsets.top) + 0.5;

                    // Bottom of parent
                    points.parent.x =
                        parseInt(pOffsets.left + config.nodeCSS.width / 2) +
                        0.5;
                    points.parent.y =
                        parseInt(pOffsets.top + $pN.outerHeight()) + 0.5;

                    // Middle of line area, above node
                    points.midpoint1.x = points.node.x;
                    points.midpoint1.y = points.node.y - 10;

                    // Middle of line area, below parent
                    points.midpoint2.x = points.parent.x;
                    points.midpoint2.y = points.midpoint1.y;

                    // Draw the line from child to parent
                    var line1 = document.createElementNS(
                        "http://www.w3.org/2000/svg",
                        "line"
                    );
                    line1.setAttributeNS(null, "x1", points.node.x);
                    line1.setAttributeNS(null, "y1", points.node.y);
                    line1.setAttributeNS(null, "x2", points.midpoint1.x);
                    line1.setAttributeNS(null, "y2", points.midpoint1.y);

                    if (node.isFirstChild || node.isLastChild) {
                        // Draw line from first child to last child (horizontal)
                        var line2 = document.createElementNS(
                            "http://www.w3.org/2000/svg",
                            "line"
                        );
                        line2.setAttributeNS(null, "x1", points.midpoint1.x);
                        line2.setAttributeNS(null, "y1", points.midpoint1.y);
                        line2.setAttributeNS(null, "x2", points.midpoint2.x);
                        line2.setAttributeNS(null, "y2", points.midpoint2.y);
                    }

                    if (!pnNode.lineToChildren) {
                        // Draw line from parent to child (vertical)
                        var line3 = document.createElementNS(
                            "http://www.w3.org/2000/svg",
                            "line"
                        );
                        line3.setAttributeNS(null, "x1", points.midpoint2.x);
                        line3.setAttributeNS(null, "y1", points.midpoint2.y);
                        line3.setAttributeNS(null, "x2", points.parentTop.x);
                        line3.setAttributeNS(null, "y2", points.parentTop.y);

                        pnNode.lineToChildren = true;
                    }

                    svg.appendChild(line1);

                    if (line2) {
                        svg.appendChild(line2);
                    }

                    if (line3) {
                        svg.appendChild(line3);
                    }

                    $genealogyContainer.prepend($svg);
                }
            });
        }



    };
})(jQuery);
