import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
    static targets = [];
    static values = {}

    connect() {

    }

    update_ships(event) {
        get('/lines/ships', {
            query: {
                line: event.target.value
            },
            responseKind: "turbo-stream"
        })
    }
}