import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
var WebFont = require("webfontloader");

function doSearch(keyword) {
    keyword = $.trim(keyword);
    let searchResultsBox = $(".search-results");
    if (keyword) {
        $.ajax({
            url: "/ajax/search",
            type: "get",
            data: {
                q: {
                    fullnamedb_or_nickname_cont: keyword,
                },
            },
            dataType: "html",
            complete: function () {
                // $("#search-numberofresults").removeClass("search-working");
            },
            success: function (resp, textStatus) {
                searchResultsBox.html(resp);
                var numberOfResults = searchResultsBox.find("li").length;
                searchResultsBox.slideDown("fast");
            },
            error: function () {
                console.log("error");
                // $(".activeNode").removeClass("activeNode");
                // $("#search-numberofresults").removeClass("search-working");
                // $("#search-clearsearch:visible").hide();
                // $("#search-results:visible").slideUp("fast", function() {
                // 	$("#search-results").get(0).innerHTML = "";
                // 	$("#search-numberofresults").text("0");
                // });
            },
        });
    } else {
        hideSearchResults();
    }
}

function hideSearchResults() {
    let searchResultsBox = $(".search-results");
    searchResultsBox.slideUp("fast");
}

function showSearchResults() {
    let searchResultsBox = $(".search-results");
    searchResultsBox.slideDown("fast");
}

function setHeaderHeightProperty() {
    let header = false;
    if ((header = document.querySelector("header.layout"))) {
        let header_height = header.offsetHeight;
        document.documentElement.style.setProperty(
            "--header-height",
            header_height + "px"
        );
    }
}

document.addEventListener("turbo:load", function() {
    setHeaderHeightProperty();

    window.addEventListener("resize", function () {
        setHeaderHeightProperty();
    });

    WebFont.load({
        google: {
            families: ["Lato"],
        },
        active: function () {
            $(".tree-wrapper").genealogyPlotter({
                before: function () {},
                after: function () {
                    // Fade in Tree
                    setTimeout(function () {
                        $("#genealogy").css({
                            opacity: 1,
                        });

                        let url = new URL(window.location.href);
                        if (url.searchParams.get("person")) {
                            // Scroll to query paramter person
                            let id = url.searchParams.get("person");
                            scrollTo(id);
                        } else {
                            // Scroll to root node
                            document
                                .querySelector('[generation="1"][position="1"]')
                                .scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                    inline: "center",
                                });
                        }

                        setTimeout(function () {
                            // Remove loading message
                            loadingMessage.remove();
                        }, 1000);
                    }, 2000);

                    var nodes = document.querySelectorAll(".node");
                    for (var i = 0, len = nodes.length; i < len; i++) {
                        nodes[i].setAttribute("tabindex", "0");
                    }
                },
            });
        },
    });

    // Change "no-js" class to "js" class
    document.body.classList.remove("no-js");
    document.body.classList.add("js");

    // Add loading message
    let loadingMessage = document.createElement("div");
    loadingMessage.innerHTML = "Drawing Genealogy<br/>Please wait...";
    loadingMessage.classList.add("loading-message");
    document.body.appendChild(loadingMessage);

    // Page move
    let drag = {
        click: { x: 0, y: 0 },
        move: { x: 0, y: 0 },
    };
    let element = document.querySelector("#genealogy");

    const mouseMoveHandler = function (e) {
        drag.move.x = e.offsetX;
        drag.move.y = e.offsetY;

        let moveTo = {
            x: drag.click.x - drag.move.x,
            y: drag.click.y - drag.move.y,
        };

        window.scrollBy(moveTo.x, moveTo.y);
    };

    const mouseDownHandler = function (e) {
        element.style.cursor = "grabbing";
        element.style.userSelect = "none";

        drag.click.x = e.offsetX;
        drag.click.y = e.offsetY;

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
    };

    const mouseUpHandler = function (e) {
        element.style.cursor = "grab";
        element.style.removeProperty("user-select");

        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
    };

    document.body.addEventListener("mousedown", mouseDownHandler);

    // Click on node functions
    document.querySelectorAll(".node:not(.node--hidden)").forEach((node) => {
        node.addEventListener("click", function (e) {
            if (!e.target.classList.contains("button")) {
                openNodeDetail(node.dataset.person_id);
            }
        });

        node.addEventListener("keydown", function (event) {
            if (event.keyCode == 13) {
                openNodeDetail(node.dataset.person_id);
            }
        });
    });

    function openNodeDetail(person_id) {
        fetch("/ajax/node_detail/" + person_id)
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                var parser = new DOMParser();
                var html = parser.parseFromString(data, "text/html");

                document.querySelector("#modal-content").innerHTML = new XMLSerializer().serializeToString(html);
                let dialog = document.querySelector("#modal-content").show();

                document
                    .querySelectorAll("#modal-content .go-to-person-button")
                    .forEach((goToPersonButton) => {
                        goToPersonButton.addEventListener("click", function () {
                            goToPerson(goToPersonButton.dataset.person_id);
                        });
                    });
            })
            .catch(function (err) {
                console.warn("Something went wrong.", err);
            });
    }

    function goToPerson(person_id) {
        setTimeout(() => {
            document
                .querySelector('[data-person_id="' + person_id + '"]')
                .scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                });
            setTimeout(() => {
                openNodeDetail(person_id);
            }, 1000);
        }, 1000);
    }

    // Scroll to root node button
    document
        .querySelector(".scroll-to-root")
        .addEventListener("click", function () {
            history.pushState({}, "", "/");
            document
                .querySelector('[generation="1"][position="1"]')
                .scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                });
        });

    // Zooming
    let el = document.querySelector("#genealogy");
    let scale = 1;

    document
        .querySelector(".js-zoom-in")
        .addEventListener("click", function () {
            scale = scale + 0.1;
            el.style.transform = "scale(" + scale + ")";
        });

    document
        .querySelector(".js-zoom-out")
        .addEventListener("click", function () {
            scale = scale - 0.1;
            el.style.transform = "scale(" + scale + ")";
        });

    document
        .querySelector(".js-zoom-reset")
        .addEventListener("click", function () {
            scale = 1;
            el.style.transform = "scale(" + scale + ")";
        });

    // Search
    var searchTimer;
    $("#fullnamedb_or_nickname_cont")
        .on("keydown", function () {
            searchTimer = setTimeout(function () {
                var v = $.trim($("#fullnamedb_or_nickname_cont").val());
                if (v.length != 0) {
                    doSearch(v);
                } else {
                    hideSearchResults();
                }
            }, 1000);
        })
        .on("submit", function () {
            return false;
        });

    document
        .getElementById("fullnamedb_or_nickname_cont")
        .addEventListener("search", function (event) {
            hideSearchResults();
        });

    $("#fullnamedb_or_nickname_cont").on("focus", function () {
        var v = $.trim($("#fullnamedb_or_nickname_cont").val());
        if (v.length > 0) {
            showSearchResults();
        }
    });

    function scrollTo(id) {
        let node = document.querySelector('[data-person_id="' + id + '"]');
        node.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
        });
        node.classList.add("highlight");
        setTimeout(function () {
            node.classList.remove("highlight");
        }, 1500);
    }

    // Click on search result
    $(".search-results").on("click", "li", function () {
        let id = $(this).attr("data-id-to-slide-to");
        hideSearchResults();
        let element = document.querySelector('[data-person_id="' + id + '"]');
        if (typeof element != "undefined" && element != null) {
            history.pushState({}, "", "?person=" + id);
            scrollTo(id);
        } else {
            window.location.replace("/?full_tree=1&person=" + id);
        }
    });
});
